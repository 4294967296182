import React from 'react';
import cn from 'classnames';
import { useSelector } from '../../lib/hooks';
import { NavBar } from './NavBar';
import { PrimaryLinkButton } from './PrimaryLinkButton';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { getPhpSitePath } from '../../lib/getPhpSitePath';

import './SalesHero.scss';

interface Props {
  searchValue: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchSubmit: (e: React.FormEvent) => void;
  className?: string;
}

export function SalesHero({
  searchValue,
  onSearchChange,
  onSearchSubmit,
  className
}: Props) {
  const account = useSelector(state => state.account.account);

  return (
    <header className={cn('uc-sales-hero', className)}>
      <NavBar
        searchValue={searchValue}
        onSearchChange={onSearchChange}
        onSearchSubmit={onSearchSubmit}
      />
      <h1 className="uc-sales-hero__title">
        <strong className="uc-sales-hero__title--strong">Urgent Care </strong>
        education that grows with you
      </h1>
      <p className="uc-sales-hero__subtitle">
        All you need to provide the best care for your patients.
      </p>
      <div className="uc-sales-hero__button-container">
        {account ? (
          <PrimaryLinkButton
            to="/uc-dashboard"
            icon="arrow-right"
            text="Go to Dashboard"
            className="uc-sales-hero__button"
            onClick={() =>
              reportLinkClickedAnalytics({
                to: 'uc-dashboard',
                linkText: 'Go to Dashboard',
                pageSpecialty: 'UC'
              })
            }
          />
        ) : (
          <PrimaryLinkButton
            href={getPhpSitePath('urgent-care/subscribe')}
            icon="arrow-right"
            text="Join Community Now"
            className="uc-sales-hero__button"
            onClick={() =>
              reportLinkClickedAnalytics({
                to: getPhpSitePath('urgent-care/subscribe'),
                linkText: 'Join Community Now',
                pageSpecialty: 'UC'
              })
            }
          />
        )}
      </div>
    </header>
  );
}
