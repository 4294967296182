import { useCallback, useState } from 'react';
import { CmeEvaluationResponse } from '../schema/cme/cmeEvaluationResponse';
import { reportError } from '../lib/reportError';
import { fetchApi } from '../lib/fetchApi';

export enum EvaluationSubmissionStatus {
  NONE = 'none',
  LOADING = 'loading',
  FAILED = 'failed',
  SUCCEEDED = 'succeeded'
}

export function useCmeEvaluationsSubmit() {
  const [submissionState, setSubmissionState] =
    useState<EvaluationSubmissionStatus>(EvaluationSubmissionStatus.NONE);

  const submitEvaluation = useCallback(
    async (
      cmeEvaluationResponses: CmeEvaluationResponse[],
      creditsIds: number[]
    ) => {
      setSubmissionState(EvaluationSubmissionStatus.LOADING);

      try {
        await fetchApi('cme_evaluations_submission', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ cmeEvaluationResponses, creditsIds })
        });
        setSubmissionState(EvaluationSubmissionStatus.SUCCEEDED);
        return true;
      } catch (error) {
        reportError(error);
        setSubmissionState(EvaluationSubmissionStatus.FAILED);
        return false;
      }
    },
    [setSubmissionState]
  );

  return { submitEvaluation, submissionState };
}
