import Cookies from 'js-cookie';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { CmeCreditsState } from '../lib/stateTypes';
import { reportError } from '../lib/reportError';
import { CmeCreditPage } from '../schema/cme/cmeCreditPage';
import { CmeFilters, cmeFiltersSchema } from '../schema/cme/cmeFilters';
import { fetchApi } from '../lib/fetchApi';
import { cmeCreditsResponseSchema } from '../schema/cme/cmeCreditsResponse';

const COOKIE_DURATION_IN_DAYS = 1;
const SAVED_FILTERS_COOKIE_NAME = 'cmeFilters';
const PAGE_SIZE = 25;

function updateSavedFiltersCookie(filters: CmeFilters) {
  Cookies.set(SAVED_FILTERS_COOKIE_NAME, JSON.stringify(filters), {
    expires: COOKIE_DURATION_IN_DAYS
  });
}

function getCmeFiltersFromCookie() {
  try {
    const cmeFiltersFromCookie = Cookies.get(SAVED_FILTERS_COOKIE_NAME);
    return cmeFiltersFromCookie !== undefined
      ? cmeFiltersSchema.parse(JSON.parse(cmeFiltersFromCookie))
      : {};
  } catch {
    return {};
  }
}

export const fetchCMECredits = async ({
  page,
  pageSize = PAGE_SIZE,
  filters
}: {
  page: number;
  pageSize?: number;
  filters: CmeFilters;
}) => {
  try {
    const parameters = new URLSearchParams({
      ...filters,
      page: page.toString(),
      pageSize: pageSize.toString()
    });
    const response = await fetchApi(`cmecredits?${parameters}`);
    const data = cmeCreditsResponseSchema.parse(await response.json());

    const pageData: CmeCreditPage = {
      ...data.data,
      pageSize: data.meta.pageSize,
      page: data.meta.page
    };
    return pageData;
  } catch (error) {
    reportError(error);
    throw error;
  }
};

const initialState: CmeCreditsState = {
  page: null,
  isLoading: false,
  cmeFilters: getCmeFiltersFromCookie(),
  cmeFiltersSnapshot: null,
  hasError: false
};

export const loadCredits = createAsyncThunk<
  CmeCreditPage | null,
  { page: number; filters: CmeFilters },
  { state: CmeCreditsState; rejectValue: null }
>('cmeCredits/loadCredits', async ({ page, filters }) => {
  try {
    const parameters = new URLSearchParams({
      ...filters,
      page: page.toString()
    });
    const response = await fetchApi(`cmecredits?${parameters}`);
    const data = cmeCreditsResponseSchema.parse(await response.json());

    const pageData: CmeCreditPage = {
      ...data.data,
      pageSize: data.meta.pageSize,
      page: data.meta.page
    };
    return pageData;
  } catch (error) {
    reportError(error);
    throw error;
  }
});

export const cmeCreditsSlice = createSlice({
  name: 'cmeCredits',
  initialState,
  reducers: {
    setCmeCreditsPage: (state, action: PayloadAction<CmeCreditPage>) => {
      state.page = action.payload;
      state.lastUpdate = new Date().getTime();
    },
    setFilters: (state, action: PayloadAction<CmeFilters>) => {
      updateSavedFiltersCookie(action.payload);
      state.cmeFilters = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadCredits.pending, state => {
        state.isLoading = true;
      })
      .addCase(loadCredits.fulfilled, (state, action) => {
        if (action.payload) {
          state.page = action.payload;
          state.cmeFiltersSnapshot = action.meta.arg.filters;
        }
        state.isLoading = false;
        state.lastUpdate = new Date().getTime();
      })
      .addCase(loadCredits.rejected, state => {
        state.isLoading = false;
        state.hasError = true;
      });
  }
});

export const {
  reducer,
  actions: { setFilters }
} = cmeCreditsSlice;
