import {
  createSlice,
  createAsyncThunk,
  AsyncThunkPayloadCreator
} from '@reduxjs/toolkit';
import { AppState, CmeFiltersOptionsState } from '../lib/stateTypes';
import { reportError } from '../lib/reportError';
import {
  CmeFiltersOptions,
  cmeFiltersOptionsSchema
} from '../schema/cme/cmeFiltersOptions';
import { fetchApi } from '../lib/fetchApi';
import { ApiError } from '../lib/ApiError';

const initialState: CmeFiltersOptionsState = {
  cmeFiltersOptions: null,
  isLoading: false
};

export const payloadCreator: AsyncThunkPayloadCreator<
  CmeFiltersOptions | null,
  void,
  { state: AppState; rejectValue: null }
> = async (_, thunkAPI) => {
  const errorContext: { extra: { [key: string]: unknown } } = {
    extra: {
      url: `cme_filters_options`
    }
  };
  try {
    const state = thunkAPI.getState();

    if (state.cmeFiltersOptions.cmeFiltersOptions !== null) {
      return state.cmeFiltersOptions.cmeFiltersOptions;
    }

    const response = await fetchApi('cme_filters_options');
    const data = await response.json();
    try {
      return cmeFiltersOptionsSchema.parse(data);
    } catch (error) {
      errorContext.extra.cme_filters_options = data;
      throw error;
    }
  } catch (error) {
    if (error instanceof ApiError) {
      errorContext.extra.status = error.status;
    }
    reportError(error, errorContext);
    return null;
  }
};

export const loadFiltersOptions = createAsyncThunk<
  CmeFiltersOptions | null,
  void,
  { state: AppState; rejectValue: null }
>('cmeFiltersOptions/loadFiltersOptions', payloadCreator);

export const cmeFiltersOptionsSlice = createSlice({
  name: 'cmeFiltersOptions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadFiltersOptions.pending, state => {
        state.isLoading = true;
      })
      .addCase(loadFiltersOptions.fulfilled, (state, action) => {
        if (action.payload) state.cmeFiltersOptions = action.payload;
        state.isLoading = false;
        state.lastUpdate = new Date().getTime();
      })
      .addCase(loadFiltersOptions.rejected, state => {
        state.isLoading = false;
      });
  }
});

export const { reducer } = cmeFiltersOptionsSlice;
