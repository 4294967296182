import { z } from 'zod';

const episodeTypeSchema = z.object({
  episode_type: z.number(),
  shortname: z.string()
});

const topicSchema = z.object({
  id: z.number(),
  title: z.string(),
  isDisabled: z.boolean()
});

export const cmeFiltersOptionsSchema = z.object({
  program: z.array(episodeTypeSchema),
  topics: z.array(topicSchema)
});

export type CmeFiltersOptions = z.infer<typeof cmeFiltersOptionsSchema>;
