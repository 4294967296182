import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/browser';
import { Provider } from 'react-redux';
import { init as initAmplitude } from '@amplitude/analytics-browser';
import { store } from './store/index';
import { loadAccount } from './store/account';
import { authWithFirebase } from './store/firebase';
import LDReduxInit from './components/LDReduxInit';
import packageInfo from '../package.json';
import App from './components/App';

Modal.setAppElement('#root');

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID || '',
    context: {
      kind: 'user',
      key: '000000',
      name: 'Anonymous User'
    },
    options: {
      // prevents the SDK from trying to use synchronous HTTP request if the page is being closed,
      // not all browsers allow these requests e.g. WebKit
      // https://launchdarkly.github.io/js-client-sdk/interfaces/LDOptions.html#disableSyncEventPost
      disableSyncEventPost: true,
      bootstrap: {
        'modernize-cme-credits': true,
        'modernize-player-page': true,
        'reskin-video-page': false
        // TODO: add more default flags when more feature flags get added to emrap-client
      }
    }
  });

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [browserTracingIntegration()],
    tracesSampleRate: parseFloat(
      process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE ?? '0'
    ),
    environment: process.env.NODE_ENV,
    release: packageInfo.version,
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport)
  });

  initAmplitude(process.env.REACT_APP_AMPLITUDE_API_KEY ?? '', {
    transport: 'beacon',
    defaultTracking: {
      attribution: true,
      pageViews: false,
      sessions: false,
      formInteractions: false,
      fileDownloads: false
    }
  });

  // Load accounts details from PHP site.
  store.dispatch(loadAccount());

  store.dispatch(authWithFirebase());

  ReactDOM.render(
    <LDProvider>
      <Provider store={store}>
        <LDReduxInit />
        <App />
      </Provider>
    </LDProvider>,
    document.getElementById('root')
  );
})();
