import { z } from 'zod';
import { metaSchema } from '../api/meta';
import { accountCmeCreditSchema } from './accountCmeCredit';

const cmeEpisodeCreditSchema = accountCmeCreditSchema.merge(
  z.object({ disclosure: z.string().nullable() })
);

export const cmeEpisodeCreditResponseSchema = z.object({
  status: z.literal('success'),
  data: z.object({
    credit: cmeEpisodeCreditSchema.nullable()
  }),
  meta: metaSchema
});

export type CmeEpisodeCredit = z.infer<typeof cmeEpisodeCreditSchema>;
