import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ContributorModal } from '../ChapterPage/ContributorModal';
import { CmeFaculty } from '../../schema/cme/cmeFaculty';
import { CmeEpisodeCredit } from '../../schema/cme/cmeEpisodeCredit';
import { DateWithWordbreak } from '../DateWithWordbreak';
import SubscriptionStatus from '../ChapterPage/SubscriptionStatus';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';
import { markCreditAsClaimed } from '../../store/cmeEpisodesCredit';
import { CmeEvaluationFlow } from '../CmeCreditsPage/CmeEvaluationFlow';
import { CmeGenericDisclosureModal } from '../CmeCreditsPage/CmeGenericDisclosureModal';
import { getPlanningCommittee } from '../../lib/getPlanningCommittee';
import { getPhpSitePath } from '../../lib/getPhpSitePath';
import { CurrentStep } from '../../lib/cmeEvaluationSteps';
import { getPageSpecialty } from '../../lib/getPageSpecialty';
import { reportModalOpenedAnalytics } from '../../analytics/reportModalOpenedAnalytics';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { isCmeCreditClaimed } from '../../lib/isCmeCreditClaimed';
import { ToggleHeading } from '../ToggleHeading';
import { Heading } from '../Heading';
import { Button } from '../Button';
import { LinkButton } from '../LinkButton';
import { ButtonLink } from '../ButtonLink';
import { Prose } from '../Prose';

import './CmeCreditsTab.scss';

interface CmeCreditsTabProps {
  cmeFaculty: CmeFaculty[];
  cmeEpisodeCredit: CmeEpisodeCredit;
  isPaidAndActive: boolean;
  isSignedIn: boolean;
  episodeType: EpisodeTypeShortname;
}

export function CmeCreditsTab({
  isSignedIn,
  cmeFaculty,
  cmeEpisodeCredit,
  episodeType,
  isPaidAndActive
}: CmeCreditsTabProps) {
  const [selectedContributor, setSelectedContributor] =
    useState<CmeFaculty | null>(null);
  const dispatch = useDispatch();
  const [evaluationStep, setEvaluationStep] = useState<CurrentStep>(
    CurrentStep.NONE
  );
  const [isGenericDisclosureModalOpen, setIsGenericDisclosureModalOpen] =
    useState(false);

  const planningCommitteeMembers = getPlanningCommittee(
    episodeType,
    cmeEpisodeCredit.startDate
  );
  const pageSpecialty = getPageSpecialty(episodeType);
  const isCreditClaimed = isCmeCreditClaimed(cmeEpisodeCredit);

  const hasPlanningCommitteeMembers = planningCommitteeMembers.length > 0;
  const hasFaculty = cmeFaculty !== null && cmeFaculty.length > 0;

  return (
    <section className="cme-credits-tab">
      <SubscriptionStatus
        isSignedIn={isSignedIn}
        isPaidAndActive={isPaidAndActive}
        tabName="cme"
        episodeType={episodeType}
      />
      <CmeGenericDisclosureModal
        isModalOpen={isGenericDisclosureModalOpen}
        handleClose={() => setIsGenericDisclosureModalOpen(false)}
        disclosureContent={
          cmeEpisodeCredit.disclosure !== null
            ? cmeEpisodeCredit.disclosure
            : ''
        }
        isLoading={false}
      />
      {evaluationStep !== CurrentStep.NONE && (
        <CmeEvaluationFlow
          evaluationCredits={[cmeEpisodeCredit]}
          currentStep={evaluationStep}
          setEvaluationStep={setEvaluationStep}
          handleEvaluationFlowCompletion={() =>
            setEvaluationStep(CurrentStep.NONE)
          }
          handleCreditsClaimed={() => {
            dispatch(
              markCreditAsClaimed({ episodeId: cmeEpisodeCredit.episode.id })
            );
          }}
          handleClose={() => setEvaluationStep(CurrentStep.NONE)}
        />
      )}
      {selectedContributor && (
        <ContributorModal
          faculty={selectedContributor}
          handleClose={() => setSelectedContributor(null)}
        />
      )}
      <header className="cme-credits-tab__header global-is-stack">
        <Heading Tag="h2" variant="xl">
          CME Credit Details
        </Heading>
        <p className="cme-credits-tab__header-highlight">
          <strong>{cmeEpisodeCredit.overallCreditHours}</strong>{' '}
          <em>AMA PRA Category 1 Credits</em>&trade; certified by PIM
        </p>
        <div className="cme-credits-tab__header-actions-container">
          {isPaidAndActive || isCreditClaimed ? (
            <Button
              variant="primary"
              onClick={() => {
                setEvaluationStep(
                  isCreditClaimed ? CurrentStep.COMPLETE : CurrentStep.CONFIRM
                );
              }}
              label={isCreditClaimed ? 'Get Certificate' : 'Claim this CME'}
            />
          ) : (
            <LinkButton
              href={getPhpSitePath(
                isSignedIn ? '/subscribe' : '/account/sign-in'
              )}
              label="Claim this CME"
              variant="secondary"
            />
          )}
          <LinkButton
            to="/account/cme"
            variant="secondary"
            onClick={() =>
              reportLinkClickedAnalytics({
                to: 'account/cme',
                linkText: 'view all my cme',
                pageSpecialty
              })
            }
            label="View all my CME"
          />
        </div>
        <dl className="cme-credits-tab__header-dates">
          <div className="cme-credits-tab__header-date-container">
            <dt className="cme-credits-tab__header-date-name">
              Original Release:
            </dt>
            <dd>
              <DateWithWordbreak timestamp={cmeEpisodeCredit.releaseDate} />
            </dd>
          </div>
          {cmeEpisodeCredit.lastReviewDate && (
            <div className="cme-credits-tab__header-date-container">
              <dt className="cme-credits-tab__header-date-name">
                Recent Review:
              </dt>
              <dd>
                <DateWithWordbreak
                  timestamp={cmeEpisodeCredit.lastReviewDate}
                />
              </dd>
            </div>
          )}
          <div className="cme-credits-tab__header-date-container">
            <dt className="cme-credits-tab__header-date-name">
              Termination Date:
            </dt>
            <dd>
              <DateWithWordbreak timestamp={cmeEpisodeCredit.expiryDate} />
            </dd>
          </div>
        </dl>
      </header>
      <section className="cme-credits-tab__credit-designation cme-credits-tab__divider global-is-stack">
        <Heading Tag="h2" variant="lg">
          Credit Designation
        </Heading>
        <ToggleHeading
          headingText="Physician Continuing Medical Education"
          nounText="details"
        >
          <p>
            {`PIM designates this enduring material for a maximum of ${cmeEpisodeCredit.overallCreditHours} `}
            <em>AMA PRA Category 1 Credit(s)</em>&trade; per month. Physicians
            should claim only the credit commensurate with the extent of their
            participation in the activity.
          </p>
        </ToggleHeading>
        <ToggleHeading
          headingText="Continuing Nursing Education"
          nounText="details"
        >
          <p>
            Contact hours are awarded for the Continuing Nursing Education
            activities. Provider approved by the California Board of Registered
            Nursing, Provider Number 13485.
          </p>
        </ToggleHeading>
        <ToggleHeading
          headingText="Continuing Physician Assistant Education"
          nounText="details"
        >
          <p>
            Postgraduate Institute for Medicine has been authorized by the
            American Academy of PAs (AAPA) to award AAPA Category 1 CME credit
            for activities planned in accordance with AAPA CME Criteria.
            Specific credit designations and approval periods are noted within
            each activity. PAs should only claim credit commensurate with the
            extent of their participation.
          </p>
        </ToggleHeading>
      </section>
      <div className="cme-credits-tab__sidebar">
        <section className="cme-credits-tab__generic-disclosure cme-credits-tab__divider global-is-stack">
          <Button
            variant="secondary"
            onClick={() => {
              reportModalOpenedAnalytics({
                modalType: 'cme generic disclosure',
                triggerText: 'CME Information',
                pageSpecialty
              });
              setIsGenericDisclosureModalOpen(true);
            }}
            label="CME Information"
            className="cme-credits-tab__generic-disclosure-button"
          />
        </section>
        {cmeEpisodeCredit.objectives !== null &&
          cmeEpisodeCredit.objectives.length > 0 && (
            <section className="cme-credits-tab__objectives cme-credits-tab__divider global-is-stack">
              <Heading Tag="h2" variant="lg">
                Objectives
              </Heading>
              <Prose htmlString={cmeEpisodeCredit.objectives} />
            </section>
          )}

        {(hasPlanningCommitteeMembers || hasFaculty) && (
          <section className="cme-credits-tab__contributors cme-credits-tab__divider global-is-stack">
            <Heading Tag="h2" variant="lg">
              Contributors
            </Heading>
            {hasPlanningCommitteeMembers && (
              <>
                <Heading Tag="h3" variant="md">
                  Planning Committee
                </Heading>
                {planningCommitteeMembers.map(member => (
                  <ButtonLink
                    key={member.fullname}
                    onClick={() => {
                      reportModalOpenedAnalytics({
                        modalType: 'cme planning committee disclosure',
                        triggerText: member.fullname,
                        pageSpecialty
                      });
                      setSelectedContributor(member);
                    }}
                  >
                    {member.fullname}
                  </ButtonLink>
                ))}
              </>
            )}
            {hasFaculty && (
              <>
                <Heading Tag="h3" variant="md">
                  Speakers
                </Heading>
                {cmeFaculty.map(faculty => (
                  <button
                    key={faculty.fullname}
                    type="button"
                    className="global-link"
                    onClick={() => {
                      reportModalOpenedAnalytics({
                        modalType: 'cme faculty disclosure',
                        triggerText: faculty.fullname,
                        pageSpecialty
                      });
                      setSelectedContributor(faculty);
                    }}
                  >
                    {faculty.fullname}
                  </button>
                ))}
              </>
            )}
          </section>
        )}
      </div>
    </section>
  );
}
