import { signInWithCustomToken } from 'firebase/auth';
import { createSlice, Dispatch } from '@reduxjs/toolkit';
import getCustomToken from '../lib/getCustomToken';
import { FirebaseAuthState } from '../lib/stateTypes';
import { auth } from '../firebase/index';
import { reportError } from '../lib/reportError';
import { AuthError } from '../lib/AuthError';

export const firebaseSlice = createSlice({
  name: 'firebase',
  initialState: {
    token: null,
    isLoading: false,
    error: undefined,
    unsubscribe: undefined,
    isCurator: false
  },
  reducers: {
    authStart: (state: FirebaseAuthState) => {
      state.token = null;
      state.isLoading = true;
      state.error = undefined;
      state.unsubscribe = undefined;
    },
    authSuccess: (state: FirebaseAuthState, action) => {
      state.token = action.payload.token;
      state.isLoading = false;
      state.error = undefined;
    },
    setIsCurator: (state: FirebaseAuthState, action) => {
      state.isCurator = action.payload;
    },
    authFailure: (state: FirebaseAuthState, action) => {
      state.token = null;
      state.isLoading = false;
      state.error = action.payload;
      // If a snapshot listener was set we must unsubscribe
      if (typeof state.unsubscribe === 'function') {
        state.unsubscribe();
      }
      state.unsubscribe = undefined;
      state.isCurator = false;
    },
    setUnsubscribe: (state: FirebaseAuthState, action) => {
      state.unsubscribe = action.payload;
    }
  }
});

export function authWithFirebase() {
  return async (dispatch: Dispatch) => {
    dispatch(firebaseSlice.actions.authStart());
    let customToken;
    try {
      customToken = await getCustomToken();
    } catch (error) {
      if (error instanceof AuthError) {
        // the user is not signed in, ignore
        dispatch(firebaseSlice.actions.authFailure('Not signed in'));
        return;
      }
      reportError(error, {
        tags: {
          getCustomToken: true,
          authWithFirebase: true,
          firestore: true
        }
      });
      dispatch(firebaseSlice.actions.authFailure(error));
    }

    if (customToken) {
      try {
        await signInWithCustomToken(auth, customToken);
        dispatch(firebaseSlice.actions.authSuccess({ token: customToken }));
      } catch (error) {
        reportError(error, {
          tags: {
            signInWithCustomToken: true,
            authWithFirebase: true,
            firestore: true
          }
        });
        dispatch(firebaseSlice.actions.authFailure(error));
      }
    } else {
      dispatch(firebaseSlice.actions.authFailure('No custom token'));
    }
  };
}
