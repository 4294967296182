import React, { useEffect, useRef, useState, Suspense, lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { useMediaQuery } from 'react-responsive';
import { createBrowserHistory } from 'history';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { track, setUserId } from '@amplitude/analytics-browser';
import { quoteURLParams } from '../lib/quoteURLParams';
import { sendMessage } from '../lib/sendMessage';
import { useSelector } from '../lib/hooks';
import { accountSlice } from '../store/account';
import { cmeCreditsSlice } from '../store/cmeCredits';
import config from '../config';
import ScrollToTopOnMount from './ScrollToTopOnMount';
import ConnectedStatusBar from './StatusBar/ConnectedStatusBar';
import MainSearch from './Search/Main';
import CitationSearch from './Search/Citation';
import { status as traumaConferenceStatus } from './Conferences/TraumaFundamentalsConferencePage/dates';
import { status as cardiologyConferenceStatus } from './Conferences/CardiologyConferencePage/dates';
import { ConnectedNav } from './Nav/ConnectedNav';
import AllEpisodes from './EpisodeGuide/AllEpisodes';
import EMRAPEpisodes from './EpisodeGuide/EMRAPEpisodes';
import EMAEpisodes from './EpisodeGuide/EMAEpisodes';
import CrunchTimeEMEpisodes from './EpisodeGuide/CrunchTimeEMEpisodes';
import CrunchTimeFMEpisodes from './EpisodeGuide/CrunchTimeFMEpisodes';
import LLSAEpisodes from './EpisodeGuide/LLSAEpisodes';
import ROPEpisodes from './EpisodeGuide/ROPEpisodes';
import ArchiveEpisodes from './EpisodeGuide/ArchiveEpisodes';
import { Showcase } from './Showcase/Showcase';
import ConferenceShowcase from './Showcase/ConferenceShowcase';
import C3LandingPage from './C3LandingPage/C3LandingPage';
import EpisodePlaylistPage from './EpisodePlaylistPage/EpisodePlaylistPage';
import { HDLandingPage } from './HDLandingPage/HDLandingPage';
import { HDPlaylistPage } from './HDPlaylistPage/HDPlaylistPage';
import HDEpisodePage from './HDPlaylistPage/HDEpisodePage';
import NotFound from './NotFound';
import SpoofMessage from './SpoofMessage/SpoofMessage';
import ConnectedHelmet from './ConnectedHelmet';
import UrgentCareShowcase from './Showcase/UrgentCareShowcase';
import { UCLandingPage } from './UrgentCare/UCLandingPage';
import { UCAudioPage } from './UrgentCare/UCAudioPage';
import { UCDashboard } from './UrgentCare/UCDashboard';
import LDReduxIdentify from './LDReduxIdentify';
import ChapterPageWrapper from './ChapterPage/ChapterPageWrapper';
import { loadMarketingSpots } from '../store/marketingSpots';
import { MarketingSlot } from './MarketingSpots/MarketingSlot';
import { FeedbackErrorPage } from './FeedbackErrorPage';
import { Breadcrumb } from './HDPlaylistPage/types';
import { PreviewDisclosurePage } from './AdminPreview/PreviewDisclosurePage';
import { PreviewEvaluationPage } from './AdminPreview/PreviewEvaluationPage';
import { FeedbackPlaylistErrorPage } from './FeedbackPlaylistErrorPage';
import Loader from './Loader';

const { mediaQueries } = config;

// Lazy loading

// EVENTS
const ConferencesLandingPage = lazy(
  () => import('./Conferences/ConferencesLandingPage/MarketingPage')
);
const PediatricEMConferencePage = lazy(
  () => import('./Conferences/PediatricEMConferencePage')
);
const TraumaFundamentalsConferencePage = lazy(
  () => import('./Conferences/TraumaFundamentalsConferencePage')
);
const CardiologyConferencePage = lazy(
  () => import('./Conferences/CardiologyConferencePage')
);
const Conference = lazy(() => import('./Conferences/Conference'));

// ACCOUNT CME
const CmeCreditsPage = lazy(() => import('./CmeCreditsPage/CmeCreditsPage'));

// HOME
const LandingPage = lazy(() => import('./LandingPage/LandingPage'));

// quote the search term on page load if it is not already quoted
quoteURLParams();

const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_NAME
});

function App() {
  const [route404, setRoute404] = useState(false);
  const { modernizePlayerPage, modernizeCmeCredits } = useFlags();
  const previousPathRef = useRef<string>();

  const dispatch = useDispatch();

  const accountState = useSelector(state => state[accountSlice.name]);
  const cmeCreditsState = useSelector(state => state[cmeCreditsSlice.name]);

  function receiveMessage(message: MessageEvent) {
    if (!message.data || message.data.source !== 'emrap-client') {
      return;
    }
    if (message.data.type === 'is404') {
      setRoute404(true);
    }
    if (message.data.type === 'is200') {
      setRoute404(false);
    }
  }

  useEffect(() => {
    // Set up a postMessage listener so the PHP app can send messages to the
    // React app.
    window.addEventListener('message', receiveMessage);

    // Send message to the PHP app to let it know the React app is ready to
    // receive messages.
    sendMessage('ready');
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  useEffect(() => {
    function trackPageView() {
      const path = window.location.pathname;
      const previousPath = previousPathRef.current;
      previousPathRef.current = window.location.pathname;

      if (path !== previousPath) {
        const eventData = {
          path: window.location.pathname,
          previousPath
        };
        track('Page_View', eventData);
      }
    }
    trackPageView(); // To track the first PageView upon load
    const unregisterCallback = history.listen(trackPageView);
    return unregisterCallback;
  }, [history]);

  useEffect(() => {
    if (accountState.account !== null)
      setUserId(accountState.account.id.toString());
  }, [accountState]);

  useEffect(() => {
    dispatch(loadMarketingSpots());
  }, []);

  const sm = useMediaQuery({ query: mediaQueries.sm });
  const media = {
    xs: !sm,
    sm,
    lg: useMediaQuery({ query: mediaQueries.lg }),
    lgMax: useMediaQuery({ query: mediaQueries.lgMax })
  };

  return (
    <>
      <MarketingSlot slotId="modal" />
      <ConnectedRouter history={history}>
        <Suspense fallback={<Loader />}>
          <LDReduxIdentify />
          <ConnectedNav />
          {accountState.hasError || cmeCreditsState.hasError ? (
            <FeedbackErrorPage />
          ) : (
            <>
              <SpoofMessage />
              {/* This is a hack to prevent the banner from being
              displayed on the conference sales page. I couldn't
              seem to get the negative lookahead regex to work so
              this does the trick.
          */}
              <Route>
                <ConnectedHelmet />
              </Route>

              <Route exact path={['/c3', '/c3/all-topics']}>
                <ScrollToTopOnMount />
                <C3LandingPage />
              </Route>

              <Route
                exact
                path={[
                  '/(episode|emrap|ema|llsa|crunchtime|rop|crunchtimefm|search)',
                  '/search/citations'
                ]}
              >
                <ConnectedStatusBar />
              </Route>

              <Switch>
                {process.env.NODE_ENV === 'development' && (
                  <Route path="/showcase">
                    <Showcase />
                  </Route>
                )}
                {process.env.NODE_ENV === 'development' && (
                  <Route path="/showcase-conferences">
                    <ConferenceShowcase />
                  </Route>
                )}
                {process.env.NODE_ENV === 'development' && (
                  <Route path="/showcase-uc">
                    <UrgentCareShowcase />
                  </Route>
                )}
                <Route exact path="/">
                  <ScrollToTopOnMount />
                  <LandingPage media={media} />
                </Route>

                <Route
                  exact
                  path="/preview-disclosure/:idType(providerId|disclosurePreviewId)/:id"
                >
                  <ScrollToTopOnMount />
                  <PreviewDisclosurePage />
                </Route>

                <Route
                  exact
                  path="/preview-evaluation/:idType(provider-id|evaluation-preview-id)/:id"
                >
                  <ScrollToTopOnMount />
                  <PreviewEvaluationPage />
                </Route>

                <Route exact path="/uc-landing">
                  <ScrollToTopOnMount />
                  <UCLandingPage />
                </Route>

                <Route exact path="/uc-dashboard">
                  <ScrollToTopOnMount />
                  <UCDashboard />
                </Route>

                <Route exact path="/uc-audio">
                  <ScrollToTopOnMount />
                  <UCAudioPage />
                </Route>

                <Route exact path="/conference/cardiology">
                  <Redirect
                    exact
                    push={false}
                    from="/conference/cardiology"
                    to="/event/cardiology"
                  />
                </Route>

                <Route exact path="/conference/pediatric-em-fundamentals">
                  <Redirect
                    exact
                    push={false}
                    from="/conference/pediatric-em-fundamentals"
                    to="/event/pediatric-em-fundamentals"
                  />
                </Route>

                <Route exact path="/conference/trauma-fundamentals">
                  <Redirect
                    exact
                    push={false}
                    from="/conference/trauma-fundamentals"
                    to="/event/trauma-fundamentals"
                  />
                </Route>

                <Route exact path="/conferences">
                  <Redirect
                    exact
                    push={false}
                    from="/conferences"
                    to="/events"
                  />
                </Route>

                <Route path="/conference/:shortname">
                  <Switch>
                    <Redirect
                      exact
                      push={false}
                      from="/conference/:shortname"
                      to="/event/:shortname"
                    />
                  </Switch>
                </Route>
                <Route
                  exact
                  path="/event/trauma-fundamentals"
                  render={() => (
                    <TraumaFundamentalsConferencePage
                      status={traumaConferenceStatus}
                    />
                  )}
                />
                <Route
                  exact
                  path="/event/pediatric-em-fundamentals"
                  component={PediatricEMConferencePage}
                />
                <Route
                  exact
                  path="/event/cardiology"
                  render={() => (
                    <CardiologyConferencePage
                      status={cardiologyConferenceStatus}
                    />
                  )}
                />
                <Route
                  exact
                  path="/events"
                  component={ConferencesLandingPage}
                />
                <Route path="/event/:shortname" component={Conference} />

                <Route exact path="/search">
                  <ScrollToTopOnMount />
                  <MainSearch media={media} />
                </Route>
                <Route exact path="/search/citations">
                  <ScrollToTopOnMount />
                  <CitationSearch media={media} />
                </Route>
                <Route exact path="/:episodeType(emrap|ema)/archive/:year">
                  <ScrollToTopOnMount />
                  <ArchiveEpisodes media={media} />
                </Route>

                <Route
                  exact
                  path="/:ordering(urgent-care)?/hd/:openMenu(all-playlists)?"
                >
                  <ScrollToTopOnMount />
                  <HDLandingPage />
                </Route>
                {modernizeCmeCredits && (
                  <Route exact path="/account/cme" component={CmeCreditsPage} />
                )}
                {modernizePlayerPage && (
                  // https://www.emrap.org/hd/playlist/latest/chapter/dailydose2022162/lftsbiliary
                  <Route
                    exact
                    path={[
                      '/:ordering(urgent-care)?/episode/:episodeShortname',
                      '/:ordering(urgent-care)?/episode/:episodeShortname/:chapterShortname',
                      '/:episodeType(c3)/playlist/:playlistShortnames+/episode/:episodeShortname', // c3 route
                      '/:ordering(urgent-care)?/:episodeType/episode/:episodeShortname/:chapterShortname', // chapter of an episode
                      '/:ordering(urgent-care)?/:episodeType/playlist/:playlistShortnames+/episode/:episodeShortname/:chapterShortname', // chapter of an episode within a playlist
                      '/:ordering(urgent-care)?/:episodeType/playlist/:playlistShortnames+/chapter/:episodeShortname/:chapterShortname' // chapter of a playlist
                    ]}
                    render={routeProps => (
                      <>
                        <ScrollToTopOnMount />
                        <ChapterPageWrapper
                          {...routeProps}
                          isDesktop={media.sm}
                        />
                      </>
                    )}
                  />
                )}
                {/* Don't render a React page for the video player pages */}
                {!modernizePlayerPage && (
                  <Route path="/:ordering(urgent-care)?/hd/playlist/:shortname+/(episode|chapter)/:episodeShortname/:chapterShortname" />
                )}
                <Route
                  path="/:ordering(urgent-care)?/hd/playlist/:shortname"
                  render={props => (
                    <>
                      <ScrollToTopOnMount />
                      <HDPlaylistPage
                        {...props}
                        breadcrumbs={[] as Breadcrumb[]}
                      />
                    </>
                  )}
                />
                <Route
                  path="/:ordering(urgent-care)?/hd/episode/:shortname"
                  render={props => (
                    <>
                      <ScrollToTopOnMount />
                      <HDEpisodePage {...props} breadcrumbs={[]} />
                    </>
                  )}
                />
                <Route
                  exact
                  path="/c3/playlist/:shortname"
                  render={({ match, ...rest }) => (
                    <>
                      <ScrollToTopOnMount />
                      <EpisodePlaylistPage
                        shortname={match.params.shortname}
                        {...rest}
                      />
                    </>
                  )}
                />
                <Route
                  exact
                  path="/episode"
                  render={routeProps => (
                    <>
                      <ScrollToTopOnMount />
                      <AllEpisodes media={media} {...routeProps} />
                    </>
                  )}
                />
                <Route
                  exact
                  path="/emrap"
                  render={routeProps => (
                    <>
                      <ScrollToTopOnMount />
                      <EMRAPEpisodes media={media} {...routeProps} />
                    </>
                  )}
                />
                <Route exact path="/ema">
                  <ScrollToTopOnMount />
                  <EMAEpisodes media={media} />
                </Route>
                <Route exact path="/llsa">
                  <ScrollToTopOnMount />
                  <LLSAEpisodes media={media} />
                </Route>
                <Route exact path="/crunchtime">
                  <ScrollToTopOnMount />
                  <CrunchTimeEMEpisodes media={media} />
                </Route>
                <Route exact path="/crunchtimefm">
                  <ScrollToTopOnMount />
                  <CrunchTimeFMEpisodes media={media} />
                </Route>
                <Route exact path="/rop">
                  <ScrollToTopOnMount />
                  <ROPEpisodes media={media} />
                </Route>
                {/* Unmatched episode type should fall through to
                the regular episode guide page
             */}
                <Redirect
                  exact
                  push={false}
                  from="/:episodeType/archive/:year"
                  to="/:episodeType"
                />
                <Redirect
                  exact
                  push={false}
                  from="/:episodeType/archive"
                  to="/:episodeType"
                />
                <Redirect
                  exact
                  push={false}
                  from="/cardiology"
                  to="/event/cardiology"
                />
                <Redirect
                  exact
                  push={false}
                  from="/pediatric-em-fundamentals"
                  to="/event/pediatric-em-fundamentals"
                />
                <Redirect
                  exact
                  push={false}
                  from="/trauma-fundamentals"
                  to="/event/trauma-fundamentals"
                />
                {/* fallback route when users try to access generated playlists on the web, which are currently only supported on the apps */}
                {/* for now only monthly-digest or digest (for no playlist type) are valid playlist type params, but this can change as more types get added */}
                {/* TODO: remove once generated playlists are supported on the web */}
                <Route
                  exact
                  path={[
                    '/playlist/:playlistType(monthly-digest|digest)/:playlistShortname',
                    '/playlist/:playlistType(monthly-digest|digest)/:playlistShortname/:chapterShortname'
                  ]}
                >
                  <FeedbackPlaylistErrorPage />
                </Route>
                {/* All routes need to go above this 404 route in order for them to also work in staging and prod since this is acting as a catch-all */}
                {route404 && (
                  <Route>
                    <NotFound
                      sourcePath={window.location.pathname}
                      component="App"
                      reason="Received 404 message from PHP site"
                    />
                  </Route>
                )}
              </Switch>
            </>
          )}
        </Suspense>
      </ConnectedRouter>
    </>
  );
}

export default App;
