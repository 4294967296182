import React from 'react';
import { getPhpSitePath } from '../../lib/getPhpSitePath';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';
import { getPageSpecialty } from '../../lib/getPageSpecialty';

import './SubscriptionStatus.scss';

export type TabName = 'summary' | 'conversation' | 'cme' | 'crunchyQs';

interface SubscriptionStatusProps {
  isSignedIn: boolean;
  isPaidAndActive: boolean;
  tabName: TabName;
  isFreeAccount?: boolean;
  episodeType: EpisodeTypeShortname;
}

const SubscriptionStatus = ({
  isSignedIn,
  isPaidAndActive,
  tabName,
  isFreeAccount,
  episodeType
}: SubscriptionStatusProps) => {
  function handleOnClick(linkText: string) {
    if (linkText.toLowerCase() === 'sign in') {
      reportLinkClickedAnalytics({
        to: `/account/sign-in`,
        linkText,
        eventSource: 'subscription banner',
        pageSpecialty: getPageSpecialty(episodeType)
      });
    } else {
      reportLinkClickedAnalytics({
        to: `/subscribe`,
        linkText,
        eventSource: 'subscription banner',
        pageSpecialty: getPageSpecialty(episodeType)
      });
    }
  }

  if (isSignedIn && isPaidAndActive) {
    return null;
  }

  let primaryMessage = '';
  let secondaryMessage = 'for full access to all episodes.';

  switch (tabName) {
    case 'summary':
      primaryMessage = !isSignedIn
        ? 'To view chapter written summaries, you need to subscribe.'
        : 'To view chapter written summaries, you need to upgrade your account.';

      break;
    case 'conversation':
      if (!isSignedIn) {
        primaryMessage = 'To join the conversation, you need to subscribe.';
      } else if (!isPaidAndActive) {
        primaryMessage = `${
          isFreeAccount ? 'Free' : 'Expired'
        } accounts can view comments, but not reply.`;
        secondaryMessage =
          'for full access to all episodes and to join the conversation.';
      }
      break;
    case 'cme':
      primaryMessage = !isSignedIn
        ? 'To earn CME, you need to subscribe.'
        : 'To earn CME, you need to upgrade your account.';
      break;
    case 'crunchyQs':
      primaryMessage = !isSignedIn
        ? 'To test your knowledge, you need to subscribe.'
        : 'To test your knowledge, you need to upgrade your account.';
      break;
    default:
      primaryMessage = '';
  }

  const secondarySectionLinks = !isSignedIn ? (
    <>
      <a
        key="sing-in"
        className="subscription-status__subscribe-link"
        href={getPhpSitePath(
          `/account/sign-in?relocate=${encodeURIComponent(
            window.location.pathname
          )}`
        )}
        onClick={event => handleOnClick(event.currentTarget.text)}
      >
        Sign in
      </a>{' '}
      or{' '}
      <a
        key="sign-up"
        className="subscription-status__subscribe-link"
        href={getPhpSitePath(
          `/subscribe?relocate=${encodeURIComponent(window.location.pathname)}`
        )}
        onClick={event => handleOnClick(event.currentTarget.text)}
      >
        sign up today
      </a>
    </>
  ) : (
    <a
      className="subscription-status__subscribe-link"
      href={getPhpSitePath(
        `/subscribe?relocate=${encodeURIComponent(window.location.pathname)}`
      )}
      onClick={event => handleOnClick(event.currentTarget.text)}
    >
      Upgrade today
    </a>
  );

  return (
    <div className="subscription-status">
      <h3 className="subscription-status__primary-content">{primaryMessage}</h3>
      <div className="subscription-status__secondary-content">
        {secondarySectionLinks} {secondaryMessage}
      </div>
    </div>
  );
};

export default SubscriptionStatus;
