import { z } from 'zod';
import { cmeCreditStatusSchema } from './cmeCreditStatus';

export const cmeFiltersSchema = z.object({
  tagsIds: z
    .string()
    .regex(/^(\d+(,\d+)*)?$/)
    .optional(), // must be numeric string comma separated
  episodeTypes: z
    .string()
    .regex(/^(0|2|11|13|14|16)(,(0|2|11|13|14|16))*$/)
    .optional(), // must be numeric string comma separated with specific values
  hoursSum: z
    .string()
    .regex(/^(0|[1-9]\d*)$/)
    .optional(), // must be numeric string
  startDate: z
    .string()
    .regex(/^\d{4}-\d{2}-\d{2}$/)
    .optional(), // must be a valid date string
  endDate: z
    .string()
    .regex(/^\d{4}-\d{2}-\d{2}$/)
    .optional(), // must be a valid date string
  status: cmeCreditStatusSchema.optional()
});

export type CmeFilters = z.infer<typeof cmeFiltersSchema>;
