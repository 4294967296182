import React from 'react';
import moment from 'moment-timezone';
import { FreeSubscription } from './FreeSubscription';
import { Renewal } from './Renewal';
import { SignedOut } from './SignedOut';
import { AutomaticRenewalExpired } from './AutomaticRenewalExpired';
import { AutomaticRenewalPastDue } from './AutomaticRenewalPastDue';
import { AutomaticRenewalCardExpiring } from './AutomaticRenewalCardExpiring';
import { Account } from '../../schema/account/account';
import { getAccountType } from '../../lib/getAccountType';

import './StatusBar.scss';

const EXPIRING_SOON_DAYS = 30;

function isAutoRenewable(account: Account | null) {
  return (
    account &&
    account.subscription &&
    account.subscription.automaticRenewalStatus !== 'manual'
  );
}

function isFreeSubscription(account: Account | null) {
  return (
    account && (!account.subscription || getAccountType({ account }) === 'free')
  );
}

function isRenewal(account: Account | null) {
  const soon = Date.now() + EXPIRING_SOON_DAYS * 24 * 60 * 60 * 1000;
  return (
    !isAutoRenewable(account) &&
    account &&
    account.subscription &&
    account.subscription.expiryDate &&
    new Date(account.subscription.expiryDate).getTime() < soon
  );
}

function isAutomaticRenewalPastDue(account: Account | null) {
  return (
    isAutoRenewable(account) &&
    account?.subscription?.automaticRenewalStatus === 'past-due'
  );
}

function isAutomaticRenewalExpired(account: Account | null) {
  return (
    isAutoRenewable(account) &&
    account?.subscription?.automaticRenewalStatus === 'expired'
  );
}

function isAutomaticRenewalCardExpiring(account: Account | null) {
  return (
    isAutoRenewable(account) &&
    account?.subscription?.cardExpiryDate &&
    account?.subscription?.expiryDate &&
    (new Date(account.subscription.expiryDate).getTime() <
      moment(account.subscription.cardExpiryDate)
        .add(1, 'M') // Card is valid for the month of expiry, check 1st of next month.
        .valueOf() ||
      new Date(account.subscription.expiryDate).getTime() < Date.now())
  );
}

export interface StatusBarProps {
  account: Account | null;
  isLoading?: boolean;
}

export function StatusBar({
  account = null,
  isLoading = false
}: StatusBarProps) {
  if (isLoading) {
    return null;
  }

  if (!account) {
    return <SignedOut />;
  }

  if (isFreeSubscription(account)) {
    return <FreeSubscription />;
  }

  if (isRenewal(account)) {
    return <Renewal account={account} />;
  }

  if (isAutomaticRenewalPastDue(account)) {
    return <AutomaticRenewalPastDue />;
  }

  if (isAutomaticRenewalExpired(account)) {
    return <AutomaticRenewalExpired />;
  }

  if (isAutomaticRenewalCardExpiring(account)) {
    return <AutomaticRenewalCardExpiring account={account} />;
  }

  return null;
}
