import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { UserCredential, signInWithCustomToken } from 'firebase/auth';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { FirebaseError } from 'firebase/app';
import isCacheValid from '../lib/isCacheValid';
import { AppState, EpisodeState } from '../lib/stateTypes';
import collections from '../firebase/collections';
import { WebEpisode, webEpisodeSchema } from '../schema/webEpisode/webEpisode';
import { db, auth } from '../firebase';
import getCustomToken from '../lib/getCustomToken';
import formatWebEpisode from '../lib/formatWebEpisode';
import { hasPaidAndActiveSubscription } from '../lib/hasPaidAndActiveSubscription';
import { reportError } from '../lib/reportError';
import { isOnline } from '../lib/isOnline';
import { isFirebaseOnline } from '../lib/isFirebaseOnline';
import { retryWithBackoff } from '../lib/retryWithBackoff';
import { AuthError } from '../lib/AuthError';
import { ApiError } from '../lib/ApiError';
import { MaximumRetryError } from '../lib/MaximumRetryError';

const CACHE_LIFETIME_MS = 30000;

export const loadEpisode = createAsyncThunk<
  WebEpisode,
  string,
  {
    state: AppState;
    rejectValue: { error: string };
  }
>('episodes/loadEpisode', async (shortname: string, thunkAPI) => {
  const state = thunkAPI.getState();
  const errorContext = {
    tags: {
      signInWithCustomToken: true,
      loadEpisode: true,
      firestore: true,
      account: state.account?.account?.id,
      subscriptionType: state.account?.account?.subscription?.type
    },
    extra: {
      episodeShortname: shortname,
      account: state.account?.account?.id,
      subscriptionType: state.account?.account?.subscription?.type
    }
  };
  const episode = state.episodes.documents[shortname];
  if (
    episode &&
    episode.data &&
    isCacheValid(episode.lastUpdate ?? null, CACHE_LIFETIME_MS)
  ) {
    return episode.data;
  }

  let isSignedIn = auth.currentUser !== null;
  let { token } = state.firebase;

  if (!isSignedIn) {
    try {
      if (!token) {
        // if the token is not set we will attempt to get a new one
        token = await retryWithBackoff<string>(
          getCustomToken,
          (error: unknown) => {
            // if the request fails and is due to the user being
            // signed out or a server error we should not retry
            if (error instanceof AuthError) {
              throw error;
            }
            if (error instanceof ApiError) {
              if (error.status === 500) {
                throw error;
              }
            }
          }
        );
      }
      if (token) {
        await retryWithBackoff<UserCredential>(
          () => signInWithCustomToken(auth, token as string),
          (error: unknown) => {
            // if the token is invalid we should not retry
            if (error instanceof FirebaseError) {
              if (
                error.code === 'auth/invalid-custom-token' ||
                error.code === 'auth/custom-token-mismatch'
              ) {
                throw error;
              }
            }
          }
        );
      }
      isSignedIn = true;
    } catch (error) {
      // we attempt to identify the type of error and report it
      // accordingly, we will still attempt to retrieve the document
      // from the public episodes collection
      if (error instanceof AuthError) {
        // the user is just signed out, don't report
      } else if (error instanceof FirebaseError) {
        if (
          error.code === 'auth/invalid-custom-token' ||
          error.code === 'auth/custom-token-mismatch'
        ) {
          // the custom token was invalid, show the public episode
          reportError(error, {
            tags: errorContext.tags,
            extra: {
              ...errorContext.extra,
              customToken: token
            }
          });
        }
      } else if (error instanceof MaximumRetryError) {
        const online = await isOnline();
        let firebaseOnline = false;
        if (online) {
          try {
            firebaseOnline = await isFirebaseOnline();
          } catch (err) {
            reportError(err, {
              tags: { ...errorContext.tags, firebaseOnlineCheckFailed: true },
              extra: errorContext.extra
            });
          }
        }

        if (!online || !firebaseOnline) {
          reportError(new Error('Failed to load episode, client is offline'), {
            tags: {
              ...errorContext.tags,
              maximumRetries: true,
              LANOnline: online,
              firebaseOnline
            },
            extra: {
              ...errorContext.extra,
              retries: error.retries
            }
          });
        } else {
          reportError(error, {
            tags: { ...errorContext.tags, maximumRetries: true },
            extra: {
              ...errorContext.extra,
              retries: error.retries
            }
          });
        }
      } else if (error instanceof Error) {
        reportError(error, errorContext);
      } else {
        reportError(new Error('Unknown error'), errorContext);
      }
    }
  }

  try {
    const querySnapshot = await getDocs(
      query(
        collection(
          db,
          isSignedIn && hasPaidAndActiveSubscription(state.account)
            ? collections.episodes
            : collections.publicEpisodes
        ),
        where('shortname', '==', shortname)
      )
    );

    if (!querySnapshot.empty) {
      const data = querySnapshot.docs[0].data();
      const formattedData = formatWebEpisode(data);
      try {
        return webEpisodeSchema.parse(formattedData);
      } catch (error) {
        reportError(error, {
          tags: { ...errorContext.tags, zod: true },
          extra: { ...errorContext.extra, episodeId: data.id }
        });
        return formattedData as WebEpisode;
      }
    } else {
      // the getDoc request will always return a snapshot even if
      // the client is disconnected so we must check the
      // network status before we can be sure that the document
      // does not exist
      const firebaseOnline = await isFirebaseOnline();

      if (firebaseOnline) {
        // the query snapshot was empty so the episode does not exist
        return thunkAPI.rejectWithValue({
          error: 'episode does not exist'
        });
      }
      return thunkAPI.rejectWithValue({
        error: 'firebase is offline'
      });
    }
  } catch (error) {
    // Report the error to Sentry with detailed context so we can
    // match it up with other errors
    reportError(error, errorContext);
    if (error instanceof Error) {
      return thunkAPI.rejectWithValue({
        error: `${error.message}\n${error.stack}`
      });
    }
    throw error;
  }
});

const initialState: EpisodeState = { documents: {}, shortnames: {} };

export const episodesSlice = createSlice({
  name: 'episodes',
  initialState,
  reducers: {
    removeEpisode: (state: EpisodeState, action) => {
      const shortname = state.shortnames[action.payload.episodeId];
      delete state.documents[shortname];
    },
    updateEpisode: (state: EpisodeState, action) => {
      const { episodeId, data } = action.payload;
      const shortname = state.shortnames[episodeId];
      if (state.documents[shortname]) {
        state.documents[shortname] = {
          isLoading: false,
          data,
          hasError: false,
          error: undefined,
          lastUpdate: new Date()
        };
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(loadEpisode.pending, (state: EpisodeState, action) => {
      const shortname = action.meta.arg;
      if (!state.documents[shortname]) {
        state.documents[shortname] = {
          isLoading: true,
          data: undefined,
          hasError: false,
          error: undefined,
          lastUpdate: undefined
        };
      }
      if (!state.documents[shortname].isLoading) {
        state.documents[shortname].isLoading = true;
        state.documents[shortname].error = undefined;
        state.documents[shortname].hasError = false;
      }
    });
    builder.addCase(loadEpisode.rejected, (state, action) => {
      const shortname = action.meta.arg;
      state.documents[shortname] = {
        isLoading: false,
        data: undefined,
        hasError: true,
        error:
          action.payload?.error || action.error.message || 'unspecified error',
        lastUpdate: undefined
      };
    });
    builder.addCase(loadEpisode.fulfilled, (state: EpisodeState, action) => {
      const shortname = action.meta.arg;
      state.documents[shortname] = {
        isLoading: false,
        data: action.payload,
        hasError: false,
        error: undefined,
        lastUpdate: new Date()
      };
      state.shortnames[action.payload.id] = shortname;
    });
  }
});
