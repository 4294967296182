import React from 'react';
import { getPhpSitePath } from '../../lib/getPhpSitePath';

const SubscribeContent = ({
  isSignedIn,
  isHDVideo,
  className,
  path
}: {
  isSignedIn: boolean;
  isHDVideo: boolean;
  className?: string;
  path: string;
}) =>
  !isSignedIn ? (
    <>
      <a
        key="subscribe-content-sign-in"
        className={className}
        href={getPhpSitePath(
          `/account/sign-in?relocate=${encodeURIComponent(path)}`
        )}
      >
        Sign in
      </a>{' '}
      or{' '}
      <a
        key="subscribe-content-sign-up"
        className={className}
        href={getPhpSitePath(`/subscribe?relocate=${encodeURIComponent(path)}`)}
      >
        sign up today
      </a>{' '}
      {isHDVideo ? 'to watch' : 'to listen'}
    </>
  ) : (
    <>
      <a
        className={className}
        href={getPhpSitePath(`/subscribe?relocate=${encodeURIComponent(path)}`)}
      >
        Upgrade today
      </a>{' '}
      {isHDVideo ? 'to watch' : 'to listen'}
    </>
  );

export default SubscribeContent;
