import React from 'react';
import parse from 'html-react-parser';
import striptags from 'striptags';
import ellipsize from 'ellipsize';
import { AllHtmlEntities } from 'html-entities';
import { passMarks } from './passMarks';
import { highlightFullText } from './highlightFullText';
import { Highlight } from '../schema/highlight';

const entities = new AllHtmlEntities();

export const resultTitle = (
  text: string,
  highlight: Highlight,
  truncate: boolean
): React.ReactElement => {
  const textInput = truncate ? ellipsize(text, 120) : text;
  if (highlight && highlight.title) {
    const html = highlightFullText(textInput, highlight.title);
    return <h2 className="title">{parse(html, { replace: passMarks })}</h2>;
  }

  return <h2 className="title">{textInput}</h2>;
};

export const resultDescription = (
  text: string,
  highlight: Highlight | null,
  truncate: boolean,
  chars = 500
) => {
  const textInput = truncate ? ellipsize(text, chars) : text;
  if (highlight && highlight.description) {
    const html = highlightFullText(textInput, highlight.description);
    return <p className="description">{parse(html, { replace: passMarks })}</p>;
  }
  return (
    <p className="description">
      {entities.decode(
        striptags(textInput).replace(/\s*\n\s*\n\s*/g, ' \u00a0•\u00a0 ')
      )}
    </p>
  );
};
